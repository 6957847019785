import $ from 'jquery';

$(document).ready(function() {
  var $tabs = $('.subscriber-category-tabs a');
  var $tabConents = $('.subscriber-category-tabcontent');
  $tabs.click(function(event) {
    event.preventDefault();
    // remove active class from all tabs and contents
    $tabs.removeClass('active');
    $tabConents.removeClass('active');
    // add active class to current tab and content
    $(this).addClass('active');
    console.log($(this).attr('href'));
    $($(this).attr('href')).addClass('active');
  });
});
